.usrimg {
  background-image: url("../../styles/img/userNameIcon.png");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 45px;
}
.pswdimg {
  background-image: url("../../styles/img/pwdIcon.png");
  background-repeat: no-repeat;
  background-position: 12px center;
  padding-left: 45px;
}

.loginPageFont {
  font-size: 114%;
  color: #343a40;
}
