



@media print{
    .receiptToPrint{
        padding: 4rem 2rem;
    }
    .receiptToPrint table td, .receiptToPrint table th{
        color: #000;
     } 
    .receiptToPrint .table-bordered th, .receiptToPrint .table-bordered td {
    border: 2px solid #dee2e6 !important;
}
}

@media print{
    .receiptToPrintp{
        padding: 4rem 2rem;
    }
    .receiptToPrintp table td, .receiptToPrintp table th{
        color: #000;
     } 
    .receiptToPrintp .table-bordered th, .receiptToPrintp .table-bordered td {
    border: 2px solid #dee2e6 !important;
}
}