@media (min-width: 992px) {


}

@media (max-width: 768px) {

}
@media (max-width: 1850px) {
 body{
     font-size: 1rem;
 }
 .wrapper{
     padding: 1.2rem 1rem;
 }
 .btn{
     font-size: 0.875rem;
     padding: 0.3rem 1rem;
 }
 label{font-size: 0.875rem;}
 .form-control{
     border: 1px solid #eee8d7;
     height: 2rem;
 }
 .form-group{
     margin-bottom: 0.5rem;
 }
 h1{font-size: 1.2rem;
margin-bottom: 0.5rem;}
 h2{
     font-size: 1rem;
     margin-bottom: 0.5rem;
 }
 h5{
     font-size: 1rem;
 }
 .additionalDetails .table-fixed tbody{
     max-height: 115px;
 }
 .addRooms .btn{font-size: 0.6rem;}
 .addRooms .table tbody{
     height: 85px;
 }
 .roomDetails .badge-pill{padding: 0.3rem;}
 .nav-tabs .nav-link{
     font-size: 1rem;
 }
 .total .borderBadge{padding: 0.3rem;}
 .total h6{font-size: 0.875rem;}
 .table{margin-bottom: 0.5rem;}
 .table thead th, .table td{
     font-size: 0.875rem;
 }
 .tableType2 .table th, .tableType2 .table td{
     font-size: 0.875rem;
     padding: 0.2rem 0.3rem;
 }
 .table-fixed tbody{max-height: 100px;}
 .roomCategoryList .table-fixed tbody{
     max-height: 375px;
 }
 .travleHistoryList .table-fixed tbody{max-height: 420px;}
 .khidmatPageContainer .khidmatList .table-fixed tbody{max-height: 490px;}



 .receiptPageContainer .inputTable table th{
     font-size: 0.875rem;
 }
 .receiptPageContainer .table-fixed tbody{max-height: 115px;}
 .roomLegends .list-inline-item:not(:last-child){
     margin-right: 0.5rem;
 }
 .roomLegends li{font-size: 0.875rem;}
 .roomPageContainer .gridContainer{height: 455px;}

 .reportPageContainer .reportListTable tbody{height:355px;}
 .badge-pill{font-size: 0.75rem;}

 .tableType3 .table thead tr:last-child th{font-size: 0.75rem;}
 .tableType3 .table td{font-size: 0.75rem;}

 .tickBtn{width: 15px;height:15px;}

 .dashboardMgrpage .nav-pills li{margin:0 0.5rem;}
}

@media (max-width: 1400px) {
    body{
        font-size: 1rem;
    }

    .currentStatus .media h5{
        font-size: 0.875rem;
    }
    .currentStatus .media p{font-size: 0.75rem;}
    .checkInVisitors ul li .media h5{
        font-size: 0.875rem;
    }
    .checkInVisitors ul li .media img{width: 2rem;}
    .checkInVisitors ul li p{ font-size: 0.875rem;}
    .currentStatus img{width: 2rem;}
  

}