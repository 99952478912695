.topbar {
    height: 3.5rem;
    border-bottom: 2px solid #ae8e40;
    background-image: url("../../../styles/img/header.jpg");
    background-size: cover;
}

.reportLink {
    margin: 0 2rem;
}
.reportName{
    cursor: pointer;
    /* background: #ae8e40; */
}
.reportName:hover{
    background: #b5aa85;
}

.notificationLink {
    margin: 0 2rem 0 0;
}

.topbar .nav-item .nav-link .img-profile {
    height: 2rem;
    width: 2rem;
}
.topbar .nav-item{
    margin:0 1rem;
}
.topbar .nav-item .nav-link {
    color: #fff;
}

.headerSearch {
    position: relative;
    width: 280px;
}

.search {
    width: 200px;
    max-width: 0;
    padding: 2px 5px;
    transition: all .5s ease;
    position: absolute;
    right: 24px;
    height: 24px;
    opacity: 0;
    border-radius: 0;
    border: 1px solid #ae8e40;
}

.search.expanded {
    max-width: 200px;
    opacity: 1;
}

.searchIcon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url(../../../styles/img/serchIcon.png);
    background-size: cover;
    position: absolute;
    right: 0;
}

.topbar .dropdown a .badge-primary {
    position: absolute;
    background: #eb3232;
    right: 0;
    top: 5px;
}

.topbar .dropdown-menu {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58, 59, 69, .15);
    top: 60px;
}

.topbar .dropdown-menu:before {
    content: "";
    position: absolute;
    top: -20px;
    right: 12px;
    border: 10px solid #343A40;
    border-color: transparent transparent #343A40 transparent;
}

.notificationDropdown {
    width: 460px;

    right: 0px;
    left: unset;
    width: 460px;
    box-shadow: 0px 5px 7px -1px #c1c1c1;
    padding-bottom: 0px;
    padding: 0px;
}

.notificationHead {
    padding: 5px 15px;
    border-radius: 3px 3px 0px 0px;
}

.notification-box {
    padding: 10px 0px;
}

.usernameAndRole{
    font-size:1.0rem;
}
.SearchList tbody{max-height: 100%;height: 500px;}
.hrsysW{
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(
        92deg) brightness(105%) contrast(104%);
}
/* .chatLink a.iconT:hover{
    filter: invert(31%) sepia(106%) saturate(887%) hue-rotate(358deg) brightness(104%) contrast(102%);
} */
/* a:hover{
    filter: invert(31%) sepia(106%) saturate(887%) hue-rotate(358deg) brightness(104%) contrast(102%);
} */