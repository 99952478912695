.sidenav {
    width: 13.625rem;
    position: relative;
    background-image: url("../../../styles/img/sidenav.jpg");
    background-size: cover;
    position: fixed;
    padding: 3.5rem 0 0 0;
    height: 100%;
    z-index: 10;
}

.sidenav .crown {
    position: relative;
    width: 100%;
    height: 3rem;
    background-image: url("../../../styles/img/crown.png");
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.sidenav .bottomCrown {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sidenav ul li a {
    color: #ededec;
    text-decoration: none;
    padding: 1rem 0.5rem 1rem 4rem;
    display: block;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 15px center;
    font-family: "IowanOldStyleBT-Roman";
    font-size: 1rem;
}

.sidenav ul li a:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    left: 0;
    top: -100%;
    background: #f9c119;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.sidenav ul li a:hover,
.sidenav ul li a.active {
    color: #f9c119;
}

.sidenav ul li a:hover::before,
.sidenav ul li a.active::before {
    top: 0;

}

.sidenav ul li.dashboard a {
    background-image: url("../../../styles/img/icons/dashboard.png");
}

.sidenav ul li.booking a {
    background-image: url("../../../styles/img/icons/booking.png");
}

.sidenav ul li.editBooking a {
    background-image: url("../../../styles/img/icons/edit-booking.png");
}

.sidenav ul li.housekeeping a {
    background-image: url("../../../styles/img/icons/edit-booking.png");
}

.sidenav ul li.rooms a {
    background-image: url("../../../styles/img/icons/rooms.png");
}

.sidenav ul li.calendar a {
    background-image: url("../../../styles/img/icons/calendar.png");
}

.sidenav ul li.check-out a {
    background-image: url("../../../styles/img/icons/check-out.png");
}

.sidenav ul li.receipt a {
    background-image: url("../../../styles/img/icons/receipt.png");
}

.sidenav ul li.onetimeniyaz a {
    background-image: url("../../../styles/img/icons/one-time-niyaz.png");
}

.sidenav ul li.chalisa a {
    background-image: url("../../../styles/img/icons/chalisa.png");
}

.sidenav ul li.khidmat a {
    background-image: url("../../../styles/img/icons/khidmat.png");
}

.sidenav ul li.faiz a {
    background-image: url("../../../styles/img/icons/one-time-niyaz.png");
}

.sidenav ul li.mannat a {
    background-image: url("../../../styles/img/icons/mannat.png");
}

.sidenav ul li.service a {
    background-image: url("../../../styles/img/icons/service.png");
}

.sidenav ul li.travelhistory a {
    background-image: url("../../../styles/img/icons/travel-history.png");
}

.sidenav ul li.bulktoken a {
    background-image: url("../../../styles/img/icons/travel-history.png");
}

.sidenav ul li.viewFeedback a {
    background-image: url("../../../styles/img/icons/view-feedback.png");
}

.sidenav ul li.tools a {
    background-image: url("../../../styles/img/icons/tools.png");
}
.sidenav ul li.managerdashboard a {
    background-image: url("../../../styles/img/icons/dashboardmgr.png");
}
.sidenav ul li.reports a {
    background-image: url("../../../styles/img/icons/reportIcon.png");
}

.navContent {
    width: 100%;
    position: relative;
    max-height: 80vh;

}